import  './config/firebaseConfigLocal'
import React from "react";
import { SharedStateProvider  } from './states/SharedStateContext';
import AppRouter from './lib/AppRouter';



function App() {

  

  return (
    <SharedStateProvider>
      <AppRouter></AppRouter>
  </SharedStateProvider>

  );

}

export default App;
