import firebase from 'firebase/compat/app';
import { getFunctions, httpsCallable  , connectFunctionsEmulator  } from "firebase/functions";
export {query}



const query = async(interfaceName,data) => {
  

  
  
  let token="";

  try {
      token =  await firebase.auth().currentUser.getIdToken();
  } catch {

  } 

  const functions = getFunctions();

  // eslint-disable-next-line no-restricted-globals
  if (location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }
  
  const functionToRun = httpsCallable(functions, interfaceName);
  try {
    let result = await functionToRun(data)
    

    let objRet = JSON.parse(result.data.data);

    if (result.data.status=='ok') return objRet;
    else throw objRet;

    


  } catch(error){

    throw error.message+" "+error.details;


  }

     
}

