import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { SharedStateContext } from '../states/SharedStateContext';
import { query } from '../lib/query';

const CreateEvent1 = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { sharedState, setSharedState } = useContext(SharedStateContext);


  useEffect(() => {


  



    firebase.auth().onAuthStateChanged(function (user) {



      query("getAuth").then(function(auth){

        setSharedState(auth);
  
      })
      

      if (user) {



        navigate('/welcome');

      } else {

        navigate('/');

      }
    });

  }, [])



  if (searchParams.get('user')) {


    const auth = getAuth();
    signInWithEmailAndPassword(auth, searchParams.get('user'), searchParams.get('password'))




  }






  return (<Container></Container>)

}


export default CreateEvent1




