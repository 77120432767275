import React, { useState, createContext } from 'react';

// Create Context
export const SharedStateContext = createContext();

// Provider Component
export const SharedStateProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState({});
  

  return (
    <SharedStateContext.Provider value={{ sharedState, setSharedState }}>
      {children}
    </SharedStateContext.Provider>
  );
};