// START  
import firebase from 'firebase/compat/app';             
import 'firebase/compat/auth';                          
const firebaseConfig =             
{
  "status": "success",
  "result": {
    "fileName": "google-config.js",
    "fileContents": "// Copy and paste this into your JavaScript code to initialize the Firebase SDK.\n// You will also need to load the Firebase SDK.\n// See https://firebase.google.com/docs/web/setup for more details.\n\nfirebase.initializeApp({\n  \"projectId\": \"soliautopay\",\n  \"appId\": \"1:817014869691:web:7548ac0783cfdc793d639e\",\n  \"storageBucket\": \"soliautopay.appspot.com\",\n  \"apiKey\": \"AIzaSyCG4g0rKLgjAkA8Xx6SaYl_1xj2L3XPLtg\",\n  \"authDomain\": \"soliautopay.firebaseapp.com\",\n  \"messagingSenderId\": \"817014869691\",\n  \"measurementId\": \"G-GPV28GDVMG\"\n});\n",
    "sdkConfig": {
      "projectId": "soliautopay",
      "appId": "1:817014869691:web:7548ac0783cfdc793d639e",
      "storageBucket": "soliautopay.appspot.com",
      "apiKey": "AIzaSyCG4g0rKLgjAkA8Xx6SaYl_1xj2L3XPLtg",
      "authDomain": "soliautopay.firebaseapp.com",
      "messagingSenderId": "817014869691",
      "measurementId": "G-GPV28GDVMG"
    }
  }
};  
let app =  firebase.initializeApp(firebaseConfig.result.sdkConfig); 
export default app;   
