import React, { useContext, useEffect, useState } from 'react';
import { query } from '../../lib/query';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper } from '@mui/material';

const Page = () => {
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    const runThis = async () => {
      const res = await query("getUsersDetails");
      setTitles(res.map(title => ({ ...title, isEditing: false }))); // Add isEditing flag
    };
    runThis();
  }, []);

  const handleEditChange = (v, id, field) => {
    
    setTitles(titles.map(title => {
      if (title.id === id) {
        return { ...title, [field]: v };
      }
      return title;
    }));
  };

  const toggleEditState = (id) => {
    setTitles(titles.map(title => {
      if (title.id === id) {
        return { ...title, isEditing: !title.isEditing };
      }
      return title;
    }));
  };

  const handleSave = async (id) => {
    const updatedTitle = titles.find(title => title.id === id);
    await query("setUserDetails",updatedTitle);
    
    //toggleEditState(id);
      const res = await query("getUsersDetails");
      setTitles(res.map(title => ({ ...title, isEditing: false }))); // Add isEditing flag


  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">הרשאות</TableCell>
            <TableCell align="right">משתמש ERP</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {titles.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
              <TableCell align="right">
                {row.isEditing ? (
                  <TextField
                    dir="ltr"
                    value={Array.isArray(row.roles)?row.roles.join(','):''}
                    onChange={(e) => handleEditChange(e.target.value?e.target.value.split(","):[], row.id, 'roles')}
                    size="small"
                  />
                ) : (
                  row.roles?row.roles.join(', '):''
                )}
              </TableCell>
              <TableCell align="right">
                {row.isEditing ? (
                  <TextField
                    dir="ltr"
                    value={row.erpUser}
                    onChange={(e) => handleEditChange(e.target.value, row.id, 'erpUser')}
                    size="small"
                  />
                ) : (
                  row.erpUser
                )}
              </TableCell>
              <TableCell align="right">
                {row.isEditing ? (
                  <Button onClick={() => handleSave(row.id)} size="small">Save</Button>
                ) : (
                  <Button onClick={() => toggleEditState(row.id)} size="small">Edit</Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Page;
