import React, { useState } from 'react';
import { query } from '../lib/query';
import firebase from 'firebase/compat/app';
import { useContext } from "react";
import { SharedStateContext } from '../states/SharedStateContext';
import { TextField, Button, Typography, Container, Paper, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';








const Page = () => {
  




  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h3" color="textPrimary" gutterBottom>
      ברוכים הבאים  !!!
      </Typography>
      <Typography variant="h5">
           למערכת הלקוחות של סוליסטיקו 
      </Typography>

      <Typography variant="body2">
          
          ניתן לקבל כאן חשבוניות וכרטסת 
          <br/>
          הפיקו הנאה מהמערכת ואל תשכחו לדווח לנו על כל רעיון או תקלה 

      </Typography>
      
      

      
      
    </Container>
  );
};
 
export default Page;

