import React from 'react';
import Menu from "./Menu"

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginEventCreate from "./LoginEventCreate"
import MainPage from '../pages/MainPage';
import InvoicesPage from '../pages/InvoicesPage';
import IndexPage from '../pages/IndexPage';
import WelcomePage from '../pages/WelcomePage';
import AdminTemplates from '../pages/admin/AdminTemplates';
import AdminConfig from '../pages/admin/AdminConfig';
import AdminMailer from '../pages/admin/AdminMailer';
import AdminUsers from '../pages/admin/AdminUsers';


const AppRouter = () => {





   return (
    

    <Router>
      <LoginEventCreate/>
     <Routes>
        <Route path="/welcome" element={<><Menu /><WelcomePage /></>} />
        <Route path="/" element={<MainPage />} />
        <Route path="/invoices" element={<><Menu /><InvoicesPage /></>} />
        <Route path="/index" element={<><Menu /><IndexPage /></>} />
        <Route path="/adminTemplates" element={<><Menu /><AdminTemplates /></>} />
        <Route path="/adminConfig" element={<><Menu /><AdminConfig /></>} />
        <Route path="/AdminMailer" element={<><Menu /><AdminMailer /></>} />
        <Route path="/AdminUsers" element={<><Menu /><AdminUsers/></>} />
        
        
  </Routes>
</Router>
  );
};

export default AppRouter;

