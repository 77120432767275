import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Container,
  Box,
  Grid,
} from '@mui/material';

const MyComponent = () => {
  const [dropdown1Value, setDropdown1Value] = useState('');
  const [dropdown2Value, setDropdown2Value] = useState('');
  const [email, setEmail] = useState('');
  const [ccVisible, setCCVisible] = useState(false);

  const handleDropdown1Change = (event) => {
    setDropdown1Value(event.target.value);
  };

  const handleDropdown2Change = (event) => {
    setDropdown2Value(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSendMail = () => {
    // Implement your sendMail logic here using dropdown1Value, dropdown2Value, and email
    console.log('Sending email...');
  };

  const handleSendTest = () => {
    // Implement your sendTest logic here using dropdown1Value, dropdown2Value, and email
    console.log('Sending test...');
  };

  const handleShowCC = () => {
    // Implement your showCC logic here using dropdown1Value and dropdown2Value
    setCCVisible(!ccVisible);
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Mailer App
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Dropdown 1</InputLabel>
              <Select value={dropdown1Value} onChange={handleDropdown1Change}>
                <MenuItem value="option1">Option 1</MenuItem>
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Dropdown 2</InputLabel>
              <Select value={dropdown2Value} onChange={handleDropdown2Change}>
                <MenuItem value="choice1">Choice 1</MenuItem>
                <MenuItem value="choice2">Choice 2</MenuItem>
                <MenuItem value="choice3">Choice 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSendMail}>
              Send Mail
            </Button>
            <Button variant="contained" color="secondary" onClick={handleSendTest}>
              Send Test
            </Button>
            <Button variant="outlined" onClick={handleShowCC}>
              {ccVisible ? 'Hide CC' : 'Show CC'}
            </Button>
          </Grid>
          {ccVisible && (
            <Grid item xs={12}>
              <Box mt={2}>
                {/* Display CC information based on dropdown1Value and dropdown2Value */}
                <Typography variant="h6">CC Information:</Typography>
                <Typography>Dropdown 1: {dropdown1Value}</Typography>
                <Typography>Dropdown 2: {dropdown2Value}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default MyComponent;
