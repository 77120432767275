import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Checkbox } from '@mui/material';
import { SharedStateContext } from '../states/SharedStateContext';
import { query } from '../lib/query';

const Page = () => {
  const { sharedState } = useContext(SharedStateContext);
  const [invoices, setInvoices] = useState([]);
  const [checkedInvoices, setCheckedInvoices] = useState([]);
  const [invoiceTotal, setInvoiceTotal] = useState({ total: 0, IVnums: [], IVs: [] });

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const fetchedInvoices = await query("getOpenDebt", { customerNumber: sharedState.CUSTNAME });
        setInvoices(fetchedInvoices);
        let ivs = [];
        fetchedInvoices.forEach(row => {

          if (row.isOpen === 1 && !row.pending) ivs.push(row)
          
        });

        setCheckedInvoices(ivs);


      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };

    fetchInvoices();
  }, [sharedState.CUSTNAME]);



  
  useEffect(() => {
    

    const newTotal = checkedInvoices.reduce((sum, inv) => sum + inv.docTotal, 0);
    const newIVnums = checkedInvoices.map(inv => inv.ref);
    const newIVs = checkedInvoices.map(inv => inv.IV);
    setInvoiceTotal({ total: newTotal, IVnums: newIVnums, IVs: newIVs });

  }, [checkedInvoices]);

  


  const handleButtonClick = async (ivValue) => {
    
    let doc  = await query("getInvoice", { IV:ivValue });

   let pdfWindow = window.open("")
   pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(doc) + "'></iframe>")
   

  };




  const handleCheckboxChange = (invoice) => {
    const newCheckedInvoices = checkedInvoices.includes(invoice)
      ? checkedInvoices.filter(inv => inv !== invoice)
      : [...checkedInvoices, invoice];

    setCheckedInvoices(newCheckedInvoices);
  };

  const handlePayInvoices =async () => {
    
    try { 
      let doc  = await query("payInvoices", invoiceTotal );
        if( doc=="OK" )  alert(doc)
        else alert(doc)

    } catch(error){

      alert(error)


    }


  };


  const handleCreateLpPageForToken =async () => {
  let doc  = await query("createLpPageForToken");
    const downloadLink = document.createElement("a");
    downloadLink.href = doc.Url;
    downloadLink.click();  
  };



  const highlightStyle = {
    backgroundColor: 'yellow',
  };

  return (
    <Container style={{ marginTop: '20px', width: 'auto' }}>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        לקוח יקר
      </Typography>
      <Typography variant="body1">
        סהכ לתשלום: {invoiceTotal.total.toFixed(2)}
        <br />
        Invoices: {invoiceTotal.IVnums.join(', ')}
      </Typography>
      <Button variant="contained" color="primary" onClick={handlePayInvoices} style={{ marginBottom: '20px' }}>
        שלם חשבוניות שנבחרו
      </Button>

      <Button variant="contained" color="primary" onClick={handleCreateLpPageForToken} style={{ marginBottom: '20px' }}>
        עדכן כרטיס במערכת
      </Button>


      
      <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">מספר חשבונית</TableCell>
              <TableCell align="right">תאריך תשלום</TableCell>
              <TableCell align="right">סהכ</TableCell>
              <TableCell align="right">חוב פתוח?</TableCell>
              <TableCell align="right">תאריך מסמך</TableCell>
              <TableCell align="right">הורדה</TableCell>
              <TableCell align="right">סמן לתשלום</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((row) => (
              <TableRow key={row.invID} style={row.isOpen === 1 ? highlightStyle : null}>
                <TableCell align="right">{row.ref}</TableCell>
                <TableCell align="right">{new Date(row.payDate * 1000).toLocaleDateString()}</TableCell>
                <TableCell align="right">{row.docTotal}</TableCell>
                <TableCell align="right">{row.isOpen ? 'Yes' : 'No'}</TableCell>
                <TableCell align="right">{new Date(row.docDate * 1000).toLocaleDateString()}</TableCell>
                <TableCell align="right">
                 
                {row.hasPdf === 1 ? (
                    <Button onClick={() => handleButtonClick(row.IV)}>הורד חשבונית</Button>
                  ) : (
                    "חסר/טרם הופק"
                  )}



                </TableCell>
                <TableCell align="right">
                  {row.isOpen === 1 && !row.pending ? (
                    <Checkbox
                      checked={checkedInvoices.includes(row)}
                      onChange={() => handleCheckboxChange(row)}
                      color="primary"
                    />
                  ) : (
                    "שולם"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Page;
