import React, { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Box, Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import { query } from '../../lib/query';

import Frame from 'react-frame-component';
  
const Page = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [titles, setTitles] = useState([]);
  const [title, setTitle] = useState([]);
  const [richText, setRichText] = useState('');




  
  useEffect(() => {
    const runThis = async () => {
      const res = await query("adminTemplatesGetTitles");
      setTitles(res);
    };
    runThis();
  }, []);


  


  useEffect(() => {
    const runThis = async () => {
      if (!selectedOption) return;

      const res = await query("adminTemplatesGetText",selectedOption);
      if (res) {
          setRichText(res);    
          setTitle(selectedOption);
      }

    };
    runThis();
  }, [selectedOption]);



  const handleSave = async (event) => {
    
    
    await query("adminTemplatesSetText",{name:title,value:richText});
    return true;


  
  } 



  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  
  }


  
  
  const handleTitleChange = (content) => {
    setTitle(content);
  };



  const handleSourctChange = (content) => {
    setRichText(content);
  };


  const handleRichTextChange = (content) => {
    setRichText(content);
  };


  const RawHTML = ({children, className = ""}) => 
<div className={className}
  dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}} />


  return (
    <Box sx={{ m: 2 }}>     
        <FormControl fullWidth>
        
        <InputLabel id="demo-simple-select-label">Options</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOption}
          label="Options"
          onChange={handleChange}
        >
          {titles.map((title, index) => (
                <MenuItem key={index} value={title}>
                  {title}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <br /><br />

      <TextField fullWidth label="Input Field" variant="outlined" value={title} 
      onChange={(e) => handleTitleChange(e.target.value)}/>
      <br /><br />
        

        <br/>
        <div dir="ltr">

      <textarea style={{width:'100%',height:'400px'}}
        value={richText} 
      onChange={e => handleSourctChange(e.target.value)}>
      </textarea>


      <Button onClick={handleSave}>שמור</Button>    


</div>
</Box>


  );
};

export default Page;

/*

<Frame style={{"width":"100%","height":"400px"}}>

<RawHTML>
        {richText}
</RawHTML> 
</Frame>  


*/