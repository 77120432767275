import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, Link } from "react-router-dom";
import firebase from 'firebase/compat/app';

import { SharedStateContext } from '../states/SharedStateContext';



const Menu = () => {
  const { sharedState, setSharedState } = useContext(SharedStateContext);


const isAuth = (role) => {

  if (sharedState.roles && sharedState.roles.indexOf(role)!=-1) return true 
  else return false 

}


const handleLogout = async () => {
  
  try {
    
    let user =await firebase.auth().signOut();
    

  }  catch(e) {
    

}



  };

  return (
    <Container>
      <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          <Link to="/welcome" variant="button" color="text.primary" sx={{ my: 1, mx: 1.5 }}>
            מערכת לקוחות {JSON.stringify(sharedState.erpUser)}
            </Link>
          </Typography>
          <nav>
            {isAuth('user')?
            <Link to="/invoices" variant="button" color="text.primary" sx={{ my: 1, mx: 1.5 }}>
              חשבונית 
            </Link>
            :null}

            &nbsp;&nbsp;&nbsp;
            {isAuth('user')?
            <Link to="/index " variant="button" color="text.primary" sx={{ my: 1, mx: 1.5 }}>
              כרטסת
            </Link>
            :null}
            &nbsp;&nbsp;&nbsp;
            {isAuth('admin')?
            <Link to="/adminTemplates " variant="button" color="text.primary" sx={{ my: 1, mx: 1.5 }}>
              תבניות
            </Link>
            :null}

&nbsp;&nbsp;&nbsp;
            {isAuth('admin')?
            
            <Link to="/adminConfig " variant="button" color="text.primary" sx={{ my: 1, mx: 1.5 }}>
              קונפיגורציה
            </Link>
            :null}


&nbsp;&nbsp;&nbsp;
            {isAuth('admin')?
            <Link to="/adminMailer " variant="button" color="text.primary" sx={{ my: 1, mx: 1.5 }}>
              מערכת דיוור   
            </Link>
            :null}


&nbsp;&nbsp;&nbsp;
            {isAuth('admin')?
            <Link to="/AdminUsers " variant="button" color="text.primary" sx={{ my: 1, mx: 1.5 }}>
              משתמשי מערכת
            </Link>
            :null}




           
          </nav>
          <Button onClick={handleLogout} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            יציאה
          </Button>
        </Toolbar>
      </AppBar>
      
    </Container>
  );
};

export default Menu;
